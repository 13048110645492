import React, { Component } from "react";
import Helmet from 'react-helmet'

import Layout from "../components/layout";
import { graphql } from 'gatsby'

import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../linkResolver';

import CallOuts from "../components/slices/CallOuts";
import Faq from "../components/slices/Faq";
import People from "../components/slices/People";
import Section from "../components/slices/Section";
import Timeline from "../components/slices/Timeline";
import ThreeColumnTable from "../components/slices/ThreeColumnTable";
import FourColumnTable from "../components/slices/FourColumnTable";
import IFrame from "../components/slices/IFrame";

import { Col, Container, Row } from "reactstrap";

class PageTemplate extends Component {
  
  getBackgroundClass(x){
    let result;
    switch (x) {
      case "gray" :
        result = "gray-bg";
        break;
      case "inset" :
        result = "inset-bg";
        break;
      default :
        result = "white-bg";
    }
    return result;
  }

  getPaddingClass(x){
    let result;
    switch (x) {
      case "Normal" :
        result = "py-3";
        break;
      case "Extra" :
        result = "py-5";
        break;
      default:
        result = "py-0";
    }
    return result;
  }

  scroll(){
      document.getElementById('content').scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  scrollTop(){
      document.getElementById('top').scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  render() {

    const node = this.props.data.allPrismicPage.edges[0].node;
    const content = JSON.parse(node.dataString);
    const title = content.title + " | " + this.props.data.site.siteMetadata.title;
    
    if(!content.body1) content.body1 = [];
    
    const slices = content.body1.map( ( slice, index ) => {
      
      let container;
      let background = 'white';
      let padding = this.getPaddingClass('');

      switch (slice.slice_type) {
        case "section" :
          container = <Section slice={slice}></Section>
          background = this.getBackgroundClass(slice.primary.section_background);
          padding = this.getPaddingClass(slice.primary.section_padding);
          break;
        case "faq" :
          container = <Faq slice={slice}></Faq>
          background = this.getBackgroundClass(slice.primary.faq_background);
          padding = this.getPaddingClass(slice.primary.faq_padding);
          break;
        case "timeline" :
          container = <Timeline slice={ slice }></Timeline>
          background = this.getBackgroundClass(slice.primary.timeline_background);
          padding = this.getPaddingClass(slice.primary.timeline_padding);
          break;
        case "call_outs" :
          container = <CallOuts slice={ slice }></CallOuts>         
          background = this.getBackgroundClass(slice.primary.callouts_background);
          padding = this.getPaddingClass(slice.primary.callouts_padding); 
          break;
        case "people" :
          container = <People slice={ slice }></People>
          background = this.getBackgroundClass(slice.primary.people_background);
          padding = this.getPaddingClass(slice.primary.people_padding);
          break;
        case "three_column_table" :
          container = <ThreeColumnTable slice={ slice }></ThreeColumnTable>
          background = this.getBackgroundClass(slice.primary.threec_background);
          padding = this.getPaddingClass(slice.primary.threec_padding);
          break;
        case "four_column_table" :
          container = <FourColumnTable slice={ slice }></FourColumnTable>
          background = this.getBackgroundClass(slice.primary.fourc_background);
          padding = this.getPaddingClass(slice.primary.fourc_padding);
          break;
        case "iframe" :
          container = <IFrame slice={ slice }></IFrame>
          background = this.getBackgroundClass(slice.primary.iframe_background);
          padding = this.getPaddingClass(slice.primary.iframe_padding);
          break;
        default:
         container = "";         
      }

      let content = <Container>
                      <Row>
                        <Col md={{ size: 10, offset: 1 }}>
                          { container }
                        </Col>
                      </Row>
                    </Container>
      
      return (
        <div key={ index }>
        
          { background === 'inset-bg' ?
            <div>
              <div>
                <div className="inset-spacer"></div>
                <div className="gray-separator-box"></div>
                <div className="gray-separator-box-inset"></div>
              </div>
              <div className='gray-bg'>
                <div className='white-inset py-3'>
                  { content }
                </div>
              </div>
              <div className="inset-footer">
                <div className="gray-separator-box"></div>
                <div className="gray-separator-box-inset"></div>
              </div>
            </div>
            : 
            <div className={ `${ background + " " + padding }` }>
              { content }
            </div>
          }
         
        </div>
      )
    });

    return (
      <Layout>
        <Helmet>      
          <title>{ title }</title>
          <meta http-equiv="Cache-Control" content="public, max-age=0, must-revalidate" />
          <meta http-equiv="Pragma" content="no-cache"/>
        </Helmet>
        <div>
          <div className="banner">
              { content.banner.url ? <img style={{width:'100%'}} src={ content.banner.url } alt='Banner' /> : "" }
            <div className="banner-slidedown">
              <a href="javascript:void(0);" onClick={this.scroll}><i className="c-backtop-1-icon fa fa-chevron-circle-down fa-custom"></i></a>
            </div>
          </div>
          <Container id="content">
            <Row>
              <Col className="mt-5" md={{ size: 10, offset: 1 }}>                  
                <h2 data-wio-id={ node.prismicId }>{ content.title }</h2>
                <div>
                  {RichText.render(content.body, linkResolver)}
                </div>
              </Col>
            </Row>
          </Container>
          
          { slices }
          <div className="c-backtop-1">
            <a href="javascript:void(0);" onClick={this.scrollTop}><i className="c-backtop-1-icon fa fa-chevron-circle-up fa-custom"></i></a>
          </div>
        </div>    
      </Layout>
    );
  }
}

export default PageTemplate;

export const query = graphql`
  query PageQuery($id: String!) {
    allPrismicPage(filter: { id: { eq: $id }}) {
      edges {
        node {
          id
          uid
          prismicId
          dataString          
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }`;