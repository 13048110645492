import React from "react";
import { Row } from 'reactstrap';
import { RichText } from 'prismic-reactjs'
import linkResolver from '../../linkResolver'

class Timeline extends React.Component {
    render () {
        const slice = this.props.slice;

        let timelineItems = slice.items;
        let half = Math.ceil(timelineItems.length / 2);
        let timelineColOne = timelineItems.slice(0, half);
        let timelineColTwo = timelineItems.slice(half, timelineItems.length);

        const itemsColOne = timelineColOne.map( (item, index) => {
            return (<TimelineItem elements={item} key={index}></TimelineItem>);
        });
        const itemsColTwo = timelineColTwo.map( (item, index) => {
            return (<TimelineItem elements={item} key={index}></TimelineItem>);
        });

        return (
                <div id={ slice.primary.timeline_anchor }>
                    { slice.primary.timeline_title ? <h3>{ slice.primary.timeline_title }</h3> : "" }
                    { slice.primary.timeline_body ? 
                        <div>{ RichText.render( slice.primary.timeline_body, linkResolver) }</div> : "" 
                    }

                    <Row className="my-3">
                        <div className="col-md-6">
                            <div className="c-timeline-1 -align-left">
                                <div className="c-timeline-1-inner -col1">
                                    { itemsColOne }
                                 </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="c-timeline-1 -align-left">
                                <div className="c-timeline-1-inner">
                                    { itemsColTwo }
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
        );
    }
}

class TimelineItem extends React.Component {
    render() {
        const item = this.props.elements;
        return (
            <div className="c-timeline-1-item">
                { item.entry_image.url ? <div className="c-timeline-1-arrow-big" style={{backgroundImage: `url(${item.entry_image.url})`,
                    backgroundPosition: 'center', backgroundSize: '85%', backgroundRepeat: 'no-repeat'}} ></div> :
                    <div className="c-timeline-1-arrow"></div> }
                <div className="c-timeline-1-header">
                    <h5>{ item.entry_title }</h5>
                </div>
                <div className="c-timeline-1-body">
                    <div> { RichText.render(item.entry_body, linkResolver)} </div>
                </div>
            </div>
        );
    }
}

export default Timeline;