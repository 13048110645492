import React from "react";
import { Col, Row } from "reactstrap";
import { getColumnProps } from "../../helpers/LayoutTool"
import { RichText } from 'prismic-reactjs'
import linkResolver from '../../linkResolver';

class Section extends React.Component {
    render () {
        const slice = this.props.slice;
        const layout = slice.primary.section_layout
        const colProps = getColumnProps(layout);
        return (
            <div id={ slice.primary.section_anchor }>
                <Row>
                    <Col { ...colProps.col1Props }>
                        { slice.primary.section_title ? <h3>{ slice.primary.section_title }</h3> : "" }
                        { slice.primary.body2 ? <div>{ RichText.render(slice.primary.body2, linkResolver) }</div> : "" }                                   
                    </Col>
                    {
                        layout && layout !== 'single-column' ?
                            <Col { ...colProps.col2Props }>
                                <div>{RichText.render(slice.primary.section_column_2_body)}</div>
                            </Col> : ""
                    }
                </Row>
            </div>
        );
    }
}

export default Section;