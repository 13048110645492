export function getColumnProps(layout) {
    var col1Props = {};
    var col2Props = {};

    if( layout && layout !== "single-column" ) {
        if(layout === "two-column-50-50"){
            col1Props['sm'] = '6';
            col2Props['sm'] = '6';
        } else if (layout === "two-column-80-20") {
            col1Props['sm'] = '8';
            col2Props['sm'] = '4';
        }
    }
    return { col1Props, col2Props }
};

