import React from 'react'
import { RichText } from 'prismic-reactjs'
import linkResolver from '../../linkResolver'
class FourColumnTable extends React.Component {
    render () {
        const slice = this.props.slice;

        const items = slice.items.map( (item, index) => {
            return <tr key={ index }>
                    <td> { RichText.render(item.fourc_column_1, linkResolver) }</td>
                    <td> { RichText.render(item.fourc_column_2, linkResolver) }</td>
                    <td> { RichText.render(item.fourc_column_3, linkResolver) }</td>
                    <td> { RichText.render(item.fourc_column_4, linkResolver) }</td>
                </tr>
        });

        return (
                <div>
                    { slice.items.length > 0 ?
                        <table className="table table-blue">
                            <thead>
                                <tr>
                                    <th>{ slice.primary.fourc_header_1 }</th>
                                    <th>{ slice.primary.fourc_header_2 }</th>
                                    <th>{ slice.primary.fourc_header_3 }</th>
                                    <th>{ slice.primary.fourc_header_4 }</th>
                                </tr>
                            </thead>
                            <tbody>
                                { items }
                            </tbody>
                        </table>
                     : 
                        slice.primary.fourc_empty_message ? 
                            <div> { RichText.render( slice.primary.fourc_empty_message, linkResolver)} </div>
                    : ""
                    }
                </div>
        );
    }
}
export default FourColumnTable;