import React from "react";
import { Container } from "reactstrap";
import CalloutGrid from "../CalloutGrid";

class Callouts extends React.Component {
    render () {
        const slice = this.props.slice;
        return <Container>
                    <CalloutGrid elements={ slice.items }></CalloutGrid>
                </Container>
    }
}
export default Callouts;