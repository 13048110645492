import React from "react";
import { Col, Row } from 'reactstrap';
import { getColumnProps } from '../../helpers/LayoutTool'
import { RichText } from 'prismic-reactjs'
import linkResolver from '../../linkResolver'

class People extends React.Component {
    render () {
        const slice = this.props.slice;
        const layout = slice.primary.people_layout;
        const colProps = getColumnProps(layout);
        const items = slice.items.map( (item, index) => {
            return (
                <Col sm="6" key={ index } className="mb-5">
                
                    <div className="media person">
                        <a href={ item.biography_file.url } title={ item.name } target="_blank" rel="noopener noreferrer">
                            <img className="align-self-start mr-3" src={ item.image.url } alt={ item.name } />
                        </a>
                        <div className="media-body">
                            <h3 className="mt-0">{ item.name }</h3>
                            <h4>{ item.title1 }</h4>
                        </div>
                    </div>

                </Col>
            );
        });
        
        return (           
            <div id={ slice.primary.people_anchor }>
                <Row>
                    <Col { ...colProps.col1Props }>
                        { slice.primary.people_title ? <h3>{ slice.primary.people_title }</h3> : "" }
                        { slice.primary.people_body ? 
                            <div>
                                { RichText.render(slice.primary.people_body, linkResolver) } 
                            </div> : "" }                   
                        { 
                            items.length > 0 ?
                                <Row className="mt-3">
                                    { items }
                                </Row>
                            : ""
                        }
                    </Col>
                    {
                        layout && layout !== 'single-column' ?
                        <Col { ...colProps.col2Props }>
                            <div>
                                { RichText.render(slice.primary.people_column_2_body, linkResolver) }
                            </div>
                        </Col> : ""
                    }
                </Row>
            
            </div>
        );
    }
}
export default People;