import React from "react";
import { Col, Row } from "reactstrap";
import { getColumnProps } from "../../helpers/LayoutTool"
import { RichText } from 'prismic-reactjs';
import linkResolver from '../../linkResolver';

class Faq extends React.Component {

    render () {

        const slice = this.props.slice;
        const layout = slice.primary.faq_layout;
        const colProps = getColumnProps(layout);
        
        const items = slice.items.map ( (item, index) => {
            return (
                <FaqItem elements={item} key={index}></FaqItem>
            );
        });
        return (
            <div>
                <Row>
                    <Col { ...colProps.col1Props }>
                        <div className="c-accordion-1 -style-outline -js-accordion">
                            <div className="c-accordion-1-inner">
                                <div className="faq" id={ slice.primary.faq_anchor }>
                                { slice.primary.faq_title ? <h3><i className="fa fa-question-circle"></i> { slice.primary.faq_title }</h3> : "" }
                                { slice.primary.faq_lead_text ? <div>{ RichText.render(slice.primary.faq_lead_text, linkResolver) }</div> : "" }
                                { items }
                                </div>
                            </div>
                        </div>
                    </Col>
                    {
                        layout && layout !== 'single-column' ?
                            <Col { ...colProps.col2Props }>
                                <div>{ RichText.render(slice.primary.faq_column_2_body, linkResolver) }</div>
                            </Col> : ""
                    }
            </Row>
            </div>
        );
    }
}

class FaqItem extends React.Component {
    constructor(props) {
        super(props);

        this.toggleOpen = this.toggleOpen.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    toggleOpen() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const item = this.props.elements;
            return (
                <div className="c-accordion-1-item" id={ item.anchor }>
                    {item.question ? <div className="c-accordion-1-header" onClick={this.toggleOpen}>
                            <span className="c-accordion-1-icon"></span>
                            <span className="c-accordion-1-title faq-question">{ item.question }</span>
                        </div> : "" }
                    { 
                        item.question ? 
                        <div className="c-accordion-1-body" style={{ display: (this.state.isOpen? 'block' : 'none' )} }>
                            { RichText.render(item.answer, linkResolver) }
                        </div> : "" 
                    }
                </div>

            );
    }

}

export default Faq;