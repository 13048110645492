import React from "react";
import { Col, Row } from "reactstrap";
import { getColumnProps } from "../../helpers/LayoutTool"
import { RichText } from "prismic-reactjs"
import linkResolver from "../../linkResolver"

class IFrame extends React.Component {
    render () {
        const slice = this.props.slice;
        const layout = slice.primary.iframe_layout;
        const colProps = getColumnProps(layout);
        
        return (
            <div>
                <Row>
                    <Col { ...colProps.col1Props }>
                        <div id={ slice.primary.iframe_anchor }>
                            { slice.primary.iframe_title ? <h3>{ slice.primary.iframe_title }</h3> : "" }
                            { slice.primary.iframe_body ? <div>{ RichText.render(slice.primary.iframe_body, linkResolver)}</div> : "" }

                            <div className="mt-4">
                                { slice.primary.iframe_height ?
                                    <div><iframe title='iframe1' className="embed-responsive-item" src={ slice.primary.iframe_url } 
                                        height={ slice.primary.iframe_height }></iframe></div> :
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe title='iframe2' className="embed-responsive-item" src={ slice.primary.iframe_url }></iframe>
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                    {
                        layout && layout !== 'single-column' ?
                            <Col { ...colProps.col2Props } className="mt-5">
                                <div>{ RichText.render(slice.primary.iframe_column_2_body, linkResolver)}</div>
                            </Col> : ""
                    }
                </Row>
            </div>
        );
    }
}
export default IFrame;